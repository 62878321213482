import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useHistory, NavLink } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import AuthService from "../utils/auth.service";
import {
	FaFacebookF,
	FaInstagram,
	FaCartPlus,
	FaSpotify,
	FaTiktok,
	FaYoutube,
} from "react-icons/fa";
import Tour from "reactour";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { getAllCards, getAllServiceTypes } from "../utils/apis/all";
import axios from "axios";
import Modal from "react-modal";

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
}

const Home = () => {
	const history = useHistory();
	const [activeFilter, setActiveFilter] = useState(1);
	const [activeSubFilter, setActiveSubFilter] = useState(null);
	const [cards, setCards] = useState<any>([]);
	const [user, setUser] = useState<any>({ id: 0 });
	const [cart, setCart] = useState<any>([]);
	const [cartNumber, setCartNumber]: any = useState(0);
	const [isTourOpen, setIsTourOpen] = useState(false);
	const [serviceTypes, setServiceTypes] = useState<any>([]);
	const [serviceSubTypes, setServiceSubTypes] = useState<any>([]);
	const [sortCriteria, setSortCriteria] = useState("followers_desc");
	const [firstArticle, setFirstArticle] = useState<any>(null);

	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleOpenModal = () => setIsModalOpen(true);
	const handleCloseModal = () => setIsModalOpen(false);

	const [zoom, setZoom] = useState(120);

	useEffect(() => {
		ReactTooltip.rebuild();
	});

	useEffect(() => {
		ReactTooltip.rebuild();
		let size = getWindowDimensions();
		if (size.width < 768) {
			setZoom(10);
		}
		console.log(size.width);

		if (size.width < 1600) {
			setZoom(90);
		}
	}, []);

	const steps = [
		{
			selector: ".step-1",
			content: "Seleziona la categoria di servizi che ti interessa",
		},
		{
			selector: ".step-2",
			content:
				"Qui troverai tutte le informazioni sulla pagina che eroga il servizio",
		},
		{
			selector: ".step-3",
			content: "Scegli il servizio che ti interessa ed aggiungilo al carrello",
		},
		{
			selector: user.id !== 0 ? ".navbar-link-button-cart" : ".step-4-login",
			content:
				"Vai al carrello per concludere il pagamento dei servizi. Verrai poi ricontattato per la consegna dei servizi acquistati",
		},
	];

	useEffect(() => {
		ReactTooltip.rebuild();
		const currentUser = AuthService.getCurrentUser();
		if (currentUser && currentUser.id !== user.id) setUser(currentUser);
		fetchFirstArticle();
	}, []);

	useEffect(() => {
		ReactTooltip.rebuild();
		getAllServiceTypes({}).then((res: any) => {
			setServiceTypes(res[0]);
			if (res[0].length > 0) {
				setActiveFilter(res[0][0].id);
				setServiceSubTypes(res[0][0].userCategories || []);
			}
		});
	}, []);

	useEffect(() => {
		fetchCards();
		let services: any = localStorage.getItem("services");
		if (services) {
			services = JSON.parse(services);
			setCart(services);
		}
	}, [activeFilter, activeSubFilter, sortCriteria]);

	const fetchFirstArticle = async () => {
		try {
			const response = await axios.get(
				"https://wordpress.listenxshop.ch/wp-json/wp/v2/posts"
			);
			const posts = response.data;

			// Create a copy of the posts to avoid direct mutation
			const updatedPosts = await Promise.all(
				posts.map(async (post: any) => {
					if (post.featured_media) {
						const mediaResponse = await axios.get(
							`https://wordpress.listenxshop.ch/wp-json/wp/v2/media/${post.featured_media}`
						);
						const media = mediaResponse.data;
						post.featured_image_url = media.source_url;
					} else {
						post.featured_image_url = null; // Set to null if there's no featured media
					}
					return post;
				})
			);

			if (response.data.length > 0) {
				setFirstArticle(response.data[0]);
			}
		} catch (error) {
			console.error("Error fetching articles:", error);
		}
	};

	const fetchCards = () => {
		let usableSubFilter = activeSubFilter ? activeSubFilter : "all";
		const [sortBy, sortDirection] = sortCriteria.split("_");

		let filters = {
			type: activeFilter.toString(),
			sortBy,
			sortDirection,
		};

		if (activeFilter == 7)
			filters = Object.assign(filters, { subType: usableSubFilter });
		else filters = Object.assign(filters, { userCategories: usableSubFilter });

		getAllCards(filters).then((res: any) => {
			setCards(res[0]);
		});
	};

	const addToCart = (serviceId: any) => {
		let services: any = localStorage.getItem("services");
		if (services) {
			services = JSON.parse(services);
			services.push(serviceId);
			setCart(services);
			localStorage.setItem("services", JSON.stringify(services));
		} else {
			localStorage.setItem("services", JSON.stringify([serviceId]));
			setCart([serviceId]);
		}
	};

	function numberWithCommas(x: any) {
		if (x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
		return "-";
	}

	function elementCount(arr: any, element: any) {
		return arr.filter((currentElement: any) => currentElement == element)
			.length;
	}

	const disableBody = (target: any) => disableBodyScroll(target);
	const enableBody = (target: any) => enableBodyScroll(target);

	const isNewService = (createdAt: string) => {
		const createdDate = new Date(createdAt);
		const currentDate = new Date();
		const diffTime = Math.abs(currentDate.getTime() - createdDate.getTime());
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		return diffDays <= 14;
	};

	return (
		<div className="main-container">
			<Row className="box-wrapper">
				<Col sm={12} md={6} className="box-black-and-white white hide-mobile">
					{firstArticle ? (
						<div style={{ marginTop: 30 }}>
							<img
								className="box-img"
								src={
									firstArticle.featured_image_url && user.id !== 0
										? firstArticle.featured_image_url
										: "https://listenxshop.ch/HOME_IMG.jpg"
								}
								style={{ width: "100%" }}
								alt="Login per continuare"
							/>
							<h3
								onClick={() => history.push("/blog/" + firstArticle.id)}
								style={{ textDecoration: "underline", cursor: "pointer" }}
							>
								{firstArticle.title.rendered}
							</h3>
						</div>
					) : (
						<div></div>
					)}
					{/*<div className="socials">
						<a
							href="https://www.instagram.com/listen.marketing"
							target="_blank"
						>
							<FaInstagram
								style={{
									fontSize: "2rem",
									color: "black",
								}}
							/>
						</a>
						<a href="#" target="_blank">
							<FaSpotify
								style={{
									fontSize: "2rem",
									color: "black",
								}}
							/>
						</a>
						<a href="#" target="_blank">
							<FaYoutube
								style={{
									fontSize: "2rem",
									color: "black",
								}}
							/>
						</a>
						<a href="#" target="_blank">
							<FaTiktok
								style={{
									fontSize: "2rem",
									color: "black",
								}}
							/>
						</a>
							</div>*/}
				</Col>

				<Col sm={12} md={6} className="box-black-and-white black">
					<h3 style={{ marginBottom: 0 }}>
						Non conosci le funzionalità del sito
					</h3>
					<button
						className="btn active costruisci-campagna"
						onClick={() => setIsTourOpen(true)}
						style={{
							paddingRight: 15,
							paddingLeft: 15,
							marginBottom: 50,
							marginTop: 0,
						}}
					>
						Impara con 4 steps
					</button>
					<h3 style={{ marginBottom: 0 }}>
						Richiedi preventivi superiori a 1000€+ di budget
					</h3>
					{/*<NavLink
						className="btn active"
						to="/contacts"
						style={{ marginTop: 0 }}
					>
						Contattaci ora
					</NavLink> */}
					<button
						className="btn active"
						onClick={() => window.Tawk_API.maximize()}
						style={{
							paddingRight: 15,
							paddingLeft: 15,
							marginBottom: 50,
							marginTop: 0,
						}}
					>
						Contattaci ora
					</button>
				</Col>
			</Row>
			<Container className="container">
				<Row style={{ marginTop: 0 }} id="categories">
					<Col sm={12} style={{ textAlign: "center" }}>
						<p className="servizi-disponibili">SERVIZI DISPONIBILI</p>
					</Col>
				</Row>
				<Row style={{ marginTop: 0 }}>
					<Col sm={12} style={{ textAlign: "center", marginBottom: 30 }}>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								gap: 20,
								overflowX: "scroll",
							}}
							className="categories step-1"
						>
							{serviceTypes.map((serviceType: any) => {
								let classes = "btn";
								if (activeFilter == serviceType.id) classes += " active";
								classes += " btn-" + serviceType.label.toLowerCase();

								return (
									<button
										onClick={() => {
											setActiveFilter(serviceType.id);
											setActiveSubFilter(null);

											if (serviceType.id !== 7) {
												setServiceSubTypes(serviceType.userCategories || []);
											} else {
												setServiceSubTypes(serviceType.serviceSubTypes);
											}
										}}
										className={classes}
										key={serviceType.id}
									>
										{serviceType.label}
									</button>
								);
							})}
						</div>
					</Col>
				</Row>
				{serviceSubTypes.length > 0 && (
					<Row style={{ marginTop: 0 }} id="categories">
						<Col sm={12} style={{ textAlign: "center" }}>
							<p className="" style={{ fontSize: 20 }}>
								Sotto Categorie
							</p>
						</Col>
					</Row>
				)}
				<Row style={{ marginTop: 0 }}>
					<Col sm={12} style={{ textAlign: "center", marginBottom: 30 }}>
						{serviceSubTypes.length > 0 && (
							<div
								style={{
									display: "flex",
									alignItems: "center",
									gap: 20,
									overflowX: "scroll",
								}}
								className="categories"
							>
								{serviceSubTypes.map((subType: any) => {
									let subClasses = "btn";
									if (activeSubFilter == subType.id) subClasses += " active";
									subClasses += " btn-" + subType.label.toLowerCase();

									return (
										<button
											onClick={() => setActiveSubFilter(subType.id)}
											className={subClasses}
											key={subType.id}
										>
											{subType.label}
										</button>
									);
								})}
							</div>
						)}
					</Col>
				</Row>
				{activeFilter !== 7 && (
					<Row>
						<Col sm={12} style={{ marginBottom: 30 }}>
							<div className="sortByDiv">
								<p>Ordina per</p>
								<select
									value={sortCriteria}
									onChange={(e) => setSortCriteria(e.target.value)}
									className="input-select sortBy"
								>
									<option value="followers_desc">Follower Decrescenti</option>
									<option value="followers_asc">Follower Crescenti</option>
									<option value="er_asc">Engagement Rate Crescente</option>
									<option value="er_desc">Engagement Rate Decrescente</option>
								</select>
							</div>
						</Col>
					</Row>
				)}
				<Row>
					{cards.map((card: any) => {
						let hasService = true;
						card.Services.forEach((service: any) => {
							if (
								service.serviceTypeId == activeFilter &&
								(!activeSubFilter ||
									service.serviceSubTypeId == activeSubFilter)
							)
								hasService = true;
						});
						if (!hasService) return null;

						return (
							<Col
								xxl={4}
								xl={4}
								lg={4}
								md={6}
								sm={12}
								style={{ marginTop: 10 }}
								key={card.id}
							>
								<div className="service" style={{ position: "relative" }}>
									{card.isTopSeller && <div className="star" />}
									<div className="service-img">
										<img
											src={
												card?.Media?.url
													? card.Media.url
													: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
											}
											alt=""
										/>
									</div>
									<div
										className={
											activeFilter !== 7
												? "service-content"
												: "service-content2"
										}
									>
										<p>{card.account}</p>
										{activeFilter !== 7 && (
											<div className="service-content-items step-2">
												<div>
													<p className="service-content-items-details">
														Followers
													</p>
													<p className="service-content-items-numbers">
														{numberWithCommas(card?.followers)}
													</p>
												</div>
												<div className="service-content-items">
													<p className="service-content-items-details">
														Eng. Rate
													</p>
													<p className="service-content-items-numbers {card.er >= 3 ? greenRate : redRate}">
														{card?.er + "%"}
													</p>
												</div>
											</div>
										)}

										<div>
											<p className="services">SERVIZI:</p>
											{card?.Services.map((service: any) => {
												if (
													service.serviceTypeId == activeFilter &&
													(!activeSubFilter ||
														service.serviceSubTypeId == activeSubFilter)
												)
													return (
														<div style={{ marginTop: 20 }} key={service.id}>
															<div
																style={{
																	display: "flex",
																	alignItems: "center",
																}}
															>
																<p
																	className="service-name"
																	style={{
																		fontSize: "1rem",
																		fontWeight: 500,
																		margin: 0,
																		flex: 50,
																		textAlign: "left",
																	}}
																	data-tip={
																		"<b>" +
																		service?.name +
																		"</b>" +
																		"<br>" +
																		service?.description
																	}
																	data-html={true}
																	data-for="default"
																>
																	{isNewService(service.createdAt) && (
																		<span
																			className="new-icon"
																			style={{ marginRight: 10, fontSize: 22 }}
																		>
																			🆕
																		</span>
																	)}
																	{service?.name}
																</p>

																{user.id != 0 && service.discount <= 0 && (
																	<p
																		style={{
																			fontSize: "1.1rem",
																			fontWeight: 500,
																			flex: 1,
																			textAlign: "right",
																			margin: 0,
																			position: "relative",
																			verticalAlign: "middle",
																		}}
																	>
																		<span
																			style={{
																				border: "1px solid white",
																				borderRadius: 20,
																				padding: "5px 8px",
																			}}
																		>
																			{service?.price}€
																		</span>
																	</p>
																)}

																{user.id != 0 && service.discount > 0 && (
																	<div
																		style={{
																			fontSize: "1.1rem",
																			fontWeight: 500,
																			flex: 1,
																			textAlign: "right",
																			margin: 0,
																			position: "relative",
																			verticalAlign: "middle",
																		}}
																		data-tip={
																			"<b style='color: #32a852'> Scontato del " +
																			service?.discount +
																			"%</b>"
																		}
																		data-html={true}
																		data-for="sconto"
																	>
																		<span className="discount-info">
																			<span className="old-price">
																				{service?.price}€
																			</span>
																			<span className="discount-percentage">
																				-{service?.discount}%
																			</span>
																			<span className="new-price">
																				{(
																					service?.price -
																					service?.price *
																						(service?.discount / 100)
																				).toFixed(2)}
																				€
																			</span>
																		</span>
																	</div>
																)}

																{user.id != 0 && (
																	<div className="add-to-cart-box step-3">
																		{cart.includes(service.id) && (
																			<span className="add-to-cart-box-after">
																				{elementCount(cart, service.id)}
																			</span>
																		)}
																		<FaCartPlus
																			style={{
																				marginLeft: 10,
																				fill: cart.includes(service.id)
																					? "#4acb00"
																					: "#5453DD",
																				cursor: "pointer",
																				marginTop: 2,
																				width: 25,
																			}}
																			className="add-to-cart"
																			onClick={() => {
																				if (service.serviceTypeId == 7) {
																					handleOpenModal();
																				} else {
																					addToCart(service.id);
																				}
																			}}
																		/>
																	</div>
																)}
																{user.id == 0 && (
																	<div
																		data-tip={"Login to view prices"}
																		data-html={true}
																		data-for="default"
																		style={{
																			fontSize: "1rem",
																			fontWeight: 500,
																			margin: 0,
																			backgroundColor: "crimson",
																			width: 20,
																			height: 19,
																			textAlign: "center",
																			borderRadius: "50%",
																			marginLeft: "auto",
																			cursor: "pointer",
																		}}
																		className="step-3"
																	>
																		?
																	</div>
																)}
															</div>
														</div>
													);
											})}
										</div>
									</div>
								</div>
							</Col>
						);
					})}
				</Row>
				<Row style={{ marginTop: 120 }} id="categories">
					<Col sm={12} style={{ textAlign: "center" }}>
						<p className="servizi-disponibili">I nostri risultati</p>
					</Col>
				</Row>
				<Row style={{ marginTop: 20 }}>
					<Col sm={12} style={{ textAlign: "center" }}>
						<div
							style={{
								position: "relative",
								width: "100%",
							}}
						>
							<object
								style={{
									width: "80%",
									minHeight: "100vh",
								}}
								type="application/pdf"
								data={
									"https://listenxshop.ch/medias/document/report.pdf?#zoom=" +
									zoom +
									"&scrollbar=0&toolbar=0&navpanes=0"
								}
							>
								<p>Report non disponibile.</p>
							</object>
						</div>
					</Col>
				</Row>
			</Container>
			<Row className="box-wrapper">
				<Col sm={12} md={6} className="box-black-and-white white hide-desktop">
					{firstArticle ? (
						<div style={{ marginTop: 30 }}>
							<img
								className="box-img"
								src={
									firstArticle.featured_image_url && user.id !== 0
										? firstArticle.featured_image_url
										: "https://listenxshop.ch/HOME_IMG.jpg"
								}
								style={{ width: "100%" }}
								alt="Login per continuare"
							/>
							<h3
								onClick={() => history.push("/blog/" + firstArticle.id)}
								style={{ textDecoration: "underline", cursor: "pointer" }}
							>
								{firstArticle.title.rendered}
							</h3>
						</div>
					) : (
						<div></div>
					)}
					{/*<div className="socials">
						<a
							href="https://www.instagram.com/listen.marketing"
							target="_blank"
						>
							<FaInstagram
								style={{
									fontSize: "2rem",
									color: "black",
								}}
							/>
						</a>
						<a href="#" target="_blank">
							<FaSpotify
								style={{
									fontSize: "2rem",
									color: "black",
								}}
							/>
						</a>
						<a href="#" target="_blank">
							<FaYoutube
								style={{
									fontSize: "2rem",
									color: "black",
								}}
							/>
						</a>
						<a href="#" target="_blank">
							<FaTiktok
								style={{
									fontSize: "2rem",
									color: "black",
								}}
							/>
						</a>
							</div>*/}
				</Col>
			</Row>
			<ReactTooltip
				id="default"
				place="top"
				type="light"
				effect="solid"
				className="tooltip"
			/>
			<ReactTooltip
				id="sconto"
				place="right"
				type="light"
				effect="solid"
				className="tooltip"
			/>
			<Tour
				steps={steps}
				isOpen={isTourOpen}
				onRequestClose={() => setIsTourOpen(false)}
				scrollDuration={5}
				rounded={5}
				onAfterOpen={disableBody}
				onBeforeClose={enableBody}
				startAt={0}
				maskSpace={-2}
			/>
			<Modal
				isOpen={isModalOpen}
				onRequestClose={handleCloseModal}
				contentLabel="Contact Us"
				style={{
					content: {
						top: "50%",
						left: "50%",
						right: "auto",
						bottom: "auto",
						marginRight: "-50%",
						transform: "translate(-50%, -50%)",
						backgroundColor: "black",
						maxWidth: "500px",
					},
				}}
			>
				<div style={{ color: "#5851db" }}>
					Per avere maggiori informazioni sull'acquisto di questo servizio
					contattaci su WhatsApp:{" "}
					<b style={{ color: "white" }}>+39 392 315 6787</b>
				</div>
				<div style={{ width: "100%", textAlign: "center", marginTop: 30 }}>
					<button onClick={handleCloseModal} className="btn active">
						Chiudi
					</button>
				</div>
			</Modal>
		</div>
	);
};

export default Home;
