import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import AuthService from "../utils/auth.service";
import { useHistory, NavLink } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import { FaShoppingCart } from "react-icons/fa";

const Navbar = (props: any) => {
	//set const [language, setLanguage] =  get from local storage
	const [language, setLanguage]: any = useState("en");
	const history = useHistory();
	const [cartNumber, setCartNumber]: any = useState(0);

	const [user, setUser] = useState({
		id: 0,
		firstName: "",
		role: 0,
		loanRequestAllowed: false,
	});
	const [backend, setBackend] = useState(false);
	useEffect(() => {
		const currentUser = AuthService.getCurrentUser();
		if (currentUser && currentUser.id !== user.id) setUser(currentUser);

		const location = window.location.href;
		if (location.indexOf("backend") > -1) setBackend(true);
		else setBackend(false);
	});

	useEffect(() => {
		ReactTooltip.rebuild();

		setLanguage(localStorage.getItem("language") || "en");
	});

	useEffect(() => {
		handleCartNumber();
	}, []);

	function handleCartNumber() {
		// wait 1 second and call againd handleCartNumber

		setTimeout(() => {
			let cartNumber: any = localStorage.getItem("services");
			if (cartNumber) {
				let app: any = JSON.parse(cartNumber);
				if (app) setCartNumber(app.length);
			}

			handleCartNumber();
		}, 500);
	}

	const handleBackend = () => {
		history.push(backend ? "/home" : "/backend/home");
	};

	const handleLogout = () => {
		history.push("/logout");
	};

	return (
		<div className="navbar">
			<Container>
				<Row>
					<details id="mobile-menu">
						<summary>
							<div>
								<NavLink className="nav_logo" to="/home" onClick={() => {}}>
									<img
										src={process.env.PUBLIC_URL + "/imgs/logo_black.png"}
										id="main-logo"
										alt=""
									/>
								</NavLink>
							</div>
							<div>
								{user.id != 0 && (
									<li
										style={{
											position: "absolute",
											top: 25,
											right: 80,
										}}
									>
										<NavLink
											className="navbar-link-button "
											to="/cart"
											onClick={() => {
												//redirect to login
											}}
										>
											<a className="notification-icon--fixed">
												<small className="notification-badge">
													{cartNumber}
												</small>
											</a>
											<FaShoppingCart className="add-to-cart step-4" />
										</NavLink>
									</li>
								)}
							</div>
							<div id="burger">
								<span></span>
								<span></span>
								<span></span>
							</div>
						</summary>
						<nav>
							<ul>
								<li>
									<NavLink
										className="navbar-link"
										to="/home"
										onClick={() => {
											//home
										}}
									>
										Home
									</NavLink>
								</li>
								<li>
									<NavLink
										className="navbar-link"
										to="/contacts"
										onClick={() => {
											//contatti
										}}
									>
										Contattaci
									</NavLink>
								</li>
								{user.id == 0 && (
									<li>
										<NavLink
											className="navbar-link-button step-4"
											to="/login"
											onClick={() => {
												//login
											}}
										>
											Login
										</NavLink>
									</li>
								)}
								{user.id == 0 && (
									<li>
										<NavLink
											className="navbar-link-button"
											to="/register"
											onClick={() => {
												//register
											}}
										>
											Register
										</NavLink>
									</li>
								)}
								{user.id != 0 && (
									<li>
										<NavLink
											className="navbar-link-button profilo-link"
											to={
												user.role === 1
													? "/dashboard/userOrders"
													: "/dashboard/orders"
											}
											onClick={() => {
												//login
											}}
										>
											Profilo
										</NavLink>
									</li>
								)}
								{user.id != 0 && (
									<li>
										<NavLink
											className="navbar-link-button logout-link"
											to="/logout"
											onClick={() => {
												//login
											}}
										>
											Logout
										</NavLink>
									</li>
								)}
							</ul>
						</nav>
					</details>
				</Row>
				<Row>
					<div id="desktop-menu">
						<NavLink className="" to="/home" onClick={() => {}}>
							<img
								src={process.env.PUBLIC_URL + "/imgs/logo_black.png"}
								id="main-logo"
								alt=""
							/>
						</NavLink>
					</div>
					<Col sm={4} id="desktop-menu">
						{user.id !== 0 && <div className="menu-icon-container"></div>}
						<div>
							<ul className="navbar-list-center">
								<li>
									<NavLink
										className="navbar-link"
										to="/home"
										onClick={() => {
											//home
										}}
									>
										Home
									</NavLink>
								</li>
								<li>
									<NavLink
										className="navbar-link"
										to="/contacts"
										onClick={() => {
											//contatti
										}}
									>
										Contattaci
									</NavLink>
								</li>
								<li>
									<NavLink
										className="navbar-link"
										to="/chi-siamo"
										onClick={() => {
											//contatti
										}}
									>
										Chi Siamo
									</NavLink>
								</li>
								<li>
									<NavLink
										className="navbar-link"
										to="/blog"
										onClick={() => {
											//contatti
										}}
									>
										Press
									</NavLink>
								</li>
							</ul>
						</div>
					</Col>
					<div id="desktop-menu" className="push-right">
						{user.id !== 0 && <div className="menu-icon-container"></div>}
						<div>
							<ul className="navbar-list">
								{user.id == 0 && (
									<li>
										<NavLink
											className="navbar-link-button step-4-login"
											to="/login"
											onClick={() => {
												//login
											}}
										>
											Login
										</NavLink>
									</li>
								)}
								{user.id == 0 && (
									<li>
										<NavLink
											className="navbar-link-button"
											to="/register"
											onClick={() => {
												//register
											}}
										>
											Register
										</NavLink>
									</li>
								)}
								{user.id != 0 && (
									<li>
										<NavLink
											className="navbar-link-button profilo-link"
											to={
												user.role === 1
													? "/dashboard/userOrders"
													: "/dashboard/orders"
											}
											onClick={() => {
												//login
											}}
										>
											Profilo
										</NavLink>
									</li>
								)}
								{user.id != 0 && (
									<li>
										<NavLink
											className="navbar-link-button logout-link"
											to="/logout"
											onClick={() => {
												//login
											}}
										>
											Logout
										</NavLink>
									</li>
								)}
								{user.id != 0 && (
									<li>
										<NavLink
											className="navbar-link-button navbar-link-button-cart "
											to="/cart"
											onClick={() => {
												//login
											}}
										>
											<a className="notification-icon--fixed">
												<small className="notification-badge">
													{cartNumber}
												</small>
											</a>
											<FaShoppingCart
												size={22}
												className=" add-to-cart-desk step-4"
											/>
										</NavLink>
									</li>
								)}
							</ul>
						</div>
					</div>
				</Row>
				<ReactTooltip
					id="account"
					aria-haspopup="true"
					place="bottom"
					type="light"
					effect="solid"
					className="tooltip"
					clickable={true}
				>
					<ul className="tooltip-list">
						{user.role == 0 && (
							<li onClick={handleBackend}>
								{backend ? "Frontend" : "Backend"}
							</li>
						)}
						<li onClick={handleLogout}>Logout</li>
					</ul>
				</ReactTooltip>
			</Container>
		</div>
	);
};

export default Navbar;
